<template>
  <div class="page-auth">
    <div class="page-main">
      <div class="main-text">请上传您的身份证正反面照片</div>
      <div class="i-body">
        <div class="i-photo" @click="handleCameraUP('Front')">
          <img class="i-photo-img" :src="imgFrontUrl ?  imgFrontUrl : identityFront"/>
        </div>

        <div class="i-photo" @click="handleCameraUP('Back')">
          <img class="i-photo-img" :src="imgBackUrl ? imgBackUrl : identityBack"/>
        </div>
      </div>
      <div class="i-req">
        <div class="req-header">
          <i class="req-frist"></i>
          <span>拍摄照片要求</span>
          <i class="req-secord"></i>
        </div>
        <div class="req-mian">
          <div v-for="item in intro" :key="item.type" class="req-main-img">
            <img :src="item.img" />
          </div>
        </div>
      </div>
    </div>
    <div class="auth-btn" @click="authVerify">认证</div>
    <Modal
      :show="showback"
      @cancel="cancel"
      :showCancel="true"
      class="infrom-modal"
      :title="'信息确认'"
    >
      <div class="bank-line">
        <span class="bank-line-name">姓名</span>
        <input
          class="bank-input"
          :value="realName"
          @change="authChange($event)"
        />
      </div>
      <div class="bank-line">
        <span class="bank-line-name">身份证号码</span>
        <input
          class="bank-input"
          :value="idCardNumber"
          @change="IDChange($event)"
        />
      </div>
      <div class="bank-line">
        <span class="bank-line-name">身份证有效期</span>
        <input
          class="bank-input"
          :value="validDate"
          @change="dateChange($event)"
        />
      </div>
      <div class="bank-picture">
        <div class="pic-img">
          <img :src="imgFrontUrl" />
          <i className="m-shui"></i>
        </div>
        <div class="pic-img pic-c">
          <img :src="imgBackUrl" />
          <i className="m-shui"></i>
        </div>
      </div>
      <div class="bank-btn" @click="confirmOcr">确认</div>
    </Modal>
  </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs, reactive } from "vue";
import { Jump } from '@/utils/routh'
import API from "../../server/api";
import Modal from "@/components/Modal/Modal.vue";
import { routerTag, EvalEvent, dealImage } from "../../utils/index";
import { Toast } from "vant";
import axios from "axios";
import { useStore } from "vuex";

export default {
  components: { Modal },

  setup() {
    const $store = useStore();
    const state = reactive({
      showback: false,
      identityFront: require("./img/cardface.png"), // 身份证正deletable: false,
      identityBack: require("./img/cardreserve.png"), // 身份证反
      imgFrontUrl: null, // 正
      imgBackUrl: null, // 反
      frontImgPath: null,
      reverseImgPath: null,
      intro: [
        {
          type: "http://supertest.jqtianxia.cn/static/imgO.png",
          img: "http://supertest.jqtianxia.cn/static/imgO.png",
          txt: "标准拍摄",
        },
        {
          type: "http://supertest.jqtianxia.cn/static/imgS.png",
          img: "http://supertest.jqtianxia.cn/static/imgS.png",
          txt: "边框缺失",
        },
        {
          type: "http://supertest.jqtianxia.cn/static/imgT.png",
          img: "http://supertest.jqtianxia.cn/static/imgT.png",
          txt: "照片模糊",
        },
        {
          type: "http://supertest.jqtianxia.cn/static/imgF.png",
          img: "http://supertest.jqtianxia.cn/static/imgF.png",
          txt: "闪光强烈",
        },
      ],
      fileName: "",
      fileKey: "",
      typeU: null,
      userInfo: {},
      realName: "", // 姓名
      idCardNumber: "", // 身份证
      validDate: "", // 有效期
      ocrInfo: null, // 正面数据新诺威尔
      ocrBackInfo: null, // 反面数据新诺威尔
      userId: "",
      showAction: false,
      showActionBack: false,
      actionPhoto: [
        {
          name: "从相册选取",
        },
        {
          name: "扫描身份证",
        },
      ],
      ocrToken:'',
      ocrKey: '20a738fd91504477a33ea4c60ba4b901',
			ocrSecret: 'c56a20723a194925aa5eaea3ef09a061',
      certInfo:{
        "timelimit": "",
        "nation": "",
        "day": "",
        "sex": "",
        "year": "",
        "address": "",
        "name": "",
        "side": 0,
        "authority": "",
        "month": "",
        "number": ""
      },
      ossData:null
    });
    const initToken=async()=>{
      // 使用新诺威尔实名活体
      const res=await API.getOcrToken()
      state.ocrToken=res.result
      console.log(state.ocrToken)
    }
    //获取oss验签
    const getOss=async()=>{
      const fileKey = "XYQB/auth";
      const res = await API.uploadOss({
          fileName: "jpg",
          fileType: fileKey,
      });
      if(res.code==200){
        state.ossData=res.result
      }
    }
    //调起OCR
    const handleCameraUP=async(type)=>{
        await getOss()
        const configs={
            token: state.ocrToken,
						type,
						key: state.ocrKey,
						secret: state.ocrSecret,
            ossData:state.ossData,
            isManualRecognize: true//打开相册true,
        }
        switch(type){
          case 'Front':
            APP.Camera_OCR_Front(configs, async (res) =>{
              if(res){
                  const result = JSON.parse(res)
                  const { fileUrl, info } = result
                  const {nation, sex, name, address, year, day, month, number} = info;
                  state.imgFrontUrl = fileUrl
                  state.certInfo.nation = nation;
                  state.certInfo.sex = sex;
                  state.certInfo.name = name;
                  state.certInfo.address = address;
                  state.certInfo.year = year;
                  state.certInfo.day = day;
                  state.certInfo.month = month;
                  state.certInfo.number = number;
                  state.frontImgPath = fileUrl; //水印照片
              }
            })
            break
          case 'Back':
            APP.Camera_OCR_Back(configs, async (res) => {
              if (res) {
                const result = JSON.parse(res)
                console.log(result,'result')
                const { fileUrl, info } = result
                state.imgBackUrl = fileUrl
                state.certInfo.authority = info.authority
                state.certInfo.timelimit = convertValidDate(info.timelimit)
                state.reverseImgPath = fileUrl;
                // console.log(state.reverseImgPath,'state.reverseImgPath')
              } else {
                Toast('请上传身份证反面照')
                return false
              }
            })
            break
        }
       
    }
     // 从新拼接身份证有效期
    const convertValidDate = (data) => {
      let sTime, eTime
      sTime = data.split('-')[0]
      eTime = data.split('-')[1]
      let sYear, sMonth, sDay, eYear, eMonth, eDay

      sYear = sTime.substring(0, 4)
      sMonth = sTime.substring(4, 6)
      sDay = sTime.substring(6, 8)

      eYear = eTime.substring(0, 4)
      eMonth = eTime.substring(4, 6)
      eDay = eTime.substring(6, 8)

      return `${sYear}.${sMonth}.${sDay}-${eYear}.${eMonth}.${eDay}`
    }
    const modalCheck = (e) => {
      state.showback = false;
    };
    const getNewFormDate = () => {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var srtDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (srtDate >= 1 && srtDate <= 9) {
        srtDate = "0" + srtDate;
      }
      var currentdate = year + month + srtDate;
      return currentdate;
    };
    // 点击认证
    const authVerify = async () => {
      // console.log(111);
      if (state.imgFrontUrl == null) {
        Toast("请上传身份证正面照");
        return false;
      }
      if (state.imgBackUrl == null) {
        Toast("请上传身份证反面照");
        return false;
      }
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      const dataPath = {};
      dataPath.frontImgPath = state.frontImgPath;
      dataPath.reverseImgPath = state.reverseImgPath;

      await authPath(dataPath);
      Toast.clear();
    };
    const authPath = async (dataPath) => {
      try {
        // 请求成功之后 再显示弹框
        const result = await API.authOcr(dataPath);
        console.log(result, 85);
        state.showback = true;
        state.userInfo = result;
        state.realName = result.realName;
        state.idCardNumber = result.idCardNumber;
        state.validDate = result.validDate;
        localStorage.setItem("authName", state.realName);
      } catch (error) {
        console.log(error);
      }
    };
    const cancel = (show) => {
      state.showback = show;
    };
    const authChange = (e) => {
      state.realName = e.target.value;
      // console.log(e.target.value,12);
    };
    const IDChange = (e) => {
      state.idCardNumber = e.target.value;
      console.log(state.idCardNumber, 997);
    };
    const dateChange = (e) => {
      state.validDate = e.target.value;
    };

    // 点击确定
    const confirmOcr = () => {
      authSub();
    };
    // 实名认证提交 face++
    const authSub = async () => {
      if (state.realName == "") {
        Toast("请填写姓名");
        return false;
      }
      if (state.idCardNumber == "") {
        Toast("请填写身份证号码");
        return false;
      }
      if (state.validDate == "") {
        Toast("请填写身份证有效期");
        return false;
      }
      const result = await API.authSubmit({
        realName: state.realName,
        address: state.userInfo.address,
        validDate: state.userInfo.validDate,
        issuedBy: state.userInfo.issueBy,
        idCard: state.idCardNumber,
        race: state.userInfo.race,
        userId: state.userId,
        frontImgPath: state.frontImgPath,
        reverseImgPath: state.reverseImgPath,
        overdueDate: state.validDate,
      });
      if (result) {
        // Jump(`${routerTag}authSuccess`);
        const pathUrl=`${routerTag}authSuccess`
        const newPath= `/pages/websrc/websrc?url=${pathUrl}`
        APP.JUMP(newPath)
      }
    };
    onMounted(() => {
      localStorage.removeItem("authName");
      state.userId = localStorage.getItem("userId");
      initToken()
    });

    return {
      ...toRefs(state),
      authVerify,
      cancel,
      modalCheck,
      authSub,
      confirmOcr,
      authChange,
      IDChange,
      dateChange,
      authPath,
      handleCameraUP
    };
  },
};
</script>
